$background-body-color: #fff;
$background-screen-color: #f1f1f1;
$background-hr-color: #ccc;
$background-button-color: #e6e6e6;
$background-pre-color: #eee;
$background-ins-color: #fff9c0;
$background-footer-color:#e8dfc9;
$background-side-color: #eef9e8;
$text-screen-color: #21759b;
$text-input-color: #666;
$text-input-focus-color: #111;
$link-color: #428bca;
$link-visited-color: purple;
$link-hover-color: #146387;
$text-main-color: #404040;

$border-button-color: #ccc #ccc #bbb;
$border-button-hover-color: #ccc #bbb #aaa;
$border-button-focus-color: #aaa #bbb #bbb;
$border-input-color: #ccc;
$border-abbr-color: #666;

//Colors
$white: #fff;
$black: #000;
$gray: #808080;
$gray-e:#eee;
$gray-d:#ddd;
$gray-c:#ccc;
$gray-9:#999;
$gray-5:#555;
$gray-2:#222;
$gray-3:#333;
$gray-7b:#7b7b7b;
$gray-2e:#2e2e2e;
$red-1:#dc143c;
$orange-1:#ffa500;
$require-color: #de4135;
$base-color: #008e95;
$font-color: #28491d;
$brand-color: #e84545;
$accent-color: #2b2e4a;
$success-color:#00e195;
$info-color:#0bbff2;
$warning-color:#ffd042;
$danger-color:#e02745;
$green-style-color:#deec9f;
$blue-style-color:#296aa3;
$red-style-color:#9c1910;
$alert-default-color:#e6e6e6;
$alert-primary-color:#3071a9;
$alert-info-color:#5bc0de;
$alert-warning-color:#f0ad4e;
$alert-danger-color:#d9534f;
$alert-pink-color:#ffa0aa;
$alert-brown-color:#b87f4a;

$nav-color: rgba($white, 0.75);
$gotop-base:#4A4D8A;
$gotop-color:rgba($gotop-base,0.8);
$gotop-color-hover:#53344b;

//Typography
$font: Hiragino Sans, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", Roboto, "Droid Sans", "Avenir", Verdana, "Lucida Grande", "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "メイリオ", Meiryo, sans-serif;
$font-secondary: "Roboto Slab" , Garamond , "Times New Roman" , "游明朝" , "Yu Mincho" , "游明朝体" , "YuMincho" , "ヒラギノ明朝 Pro W3" , "Hiragino Mincho Pro" , "HiraMinProN-W3" , "HGS明朝E" , serif;
$font-accent: "Cardo", Georgia, 'Times New Roman', serif;
$font-main: sans-serif;
$font-code: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font-pre: "Courier 10 Pitch", Courier, monospace;

$font-line-height-body: 1.5;
$font-line-height-pre: 1.6;
$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

$font-size: 14px;
$h1-font-size: 65px;
$h2-font-size: 40px;
$h3-font-size: 28px;
$h4-font-size: 22px;
$h5-font-size: 14px;
$nav-font-size: 13px;

$line-height: $font-size * 1.6;
$size-site-main: 100%;
$size-site-sidebar: 25%;

// Breakpoints
$bp-small: 320px;
$bp-small-2: 480px;
$bp-small-3: 568px;
$bp-medium: 640px;
$bp-medium-2: 768px;
$bp-large: 1024px;

.backgroundCover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

section {
  &#features, &#buyNow, &#twitter, &.well, &.page, &#footerimg {
    background-size: cover;
  }
}

body {
  font-family: $font;
  background: $white;
}

.mincho {
  font-family:$font-secondary ;
}

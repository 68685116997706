
/* googlemap views*/


#gmap {
	height:400px;
	max-height: 90%;
	width: 95%;
	margin: 0 auto 15px;
	p {
		line-height: 1.5;
		padding: 0;
	}
	strong {
		line-height: 2;
		color: $black;
	}
	label {
		width: auto;
		display: inline;
	}
	img {
		max-width: none;
		margin:0;
		padding:0;
	}
}


/*googlemap bugfix for ja*/

.gm-style-iw {
	overflow: hidden;
	white-space: nowrap;
	div{
		overflow: hidden
	}
}

article#sample #map{
	width: 100%;
	min-height: 300px;
}
@media(min-width:768px) {
	article#sample #map{
		min-height: 700px;
	}
}
.breadcrumb {
	font-size: 12px;
}

.scrollpoint {
	opacity: 0;
	&.active {
		opacity: 1 \0;
	}
}

dl.faq {
	overflow: hidden;
	_zoom: 1;
	dd {
		text-indent: -3em;
		padding: 0 30px 0 50px;
	}
	dt {
		text-indent: -3em;
		padding: 0 30px 0 50px;
		margin: 10px 10px 0 0;
	}
	dd:first-letter, dt:first-letter {
		font-size: 1.6em;
		font-weight: bold;
		margin-right: 10px;
		padding: 5px;
	}
	dd {
		margin: 10px 0 -1px 0;
		padding-bottom: 15px;
	}
	dt:first-letter {
		color: #327ab7;
	}
	dd:first-letter {
		color: #d9534f;
	}
}

ul {
	&.snsb {
		padding: 10px 0;
		margin-bottom: 0;
		font-family: sans-serif;
		line-height: 150%;
	}
	&.an > li {
		margin-bottom: 15px;
	}
}

.snsb li {
	float: left;
	list-style-type: none;
	margin-left: 0;
	margin-right: 7px;
	margin-top: 2px;
	margin-bottom: 5px;
}

#sample {
	#test {
		ul {
			margin: 0;
			padding: 0;
		}
		li {
			display: block;
			overflow: hidden;
			border-bottom: 1px solid #ccc;
			padding: 15px;
			cursor: pointer;
			&:hover {
				background-color: #c8f0ff;
			}
		}
	.icon {
		float: left;
		width: 30%;
		img {
			width: 100%;
		}
	}
	dl {
		float: right;
		width: 68%;
	}
	dt {
		font-weight: bold;
		margin-bottom: 15px;
	}
}
	.green {
		width: 2em;
		height: 2em;
		background-color: green;
	}
	.blue {
		width: 2em;
		height: 2em;
		background-color: blue;
	}
	.red {
		width: 2em;
		height: 2em;
		background-color: red;
	}
	.squere {
		width: 3em;
		height: 3em;
		background-color: blue;
	}
	.fireworks {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.btns {
	display: inline-block;
	vertical-align: middle;
	margin: .4em;
	padding: 1em;
	cursor: pointer;
	background: #e1e1e1;
	text-decoration: none;
	color: #666;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.btn {
	&.btn-primary {
		background: $link-color;
		border: none;
		text-transform: uppercase;
		transition: all 0.25s ease-in-out;
		font-weight: bold;
		font-size: 16px;
		padding: 15px 40px;
		&:hover {
			background: #1d93ca;
		}
	}
	&.btn-empty {
		background: none;
		border: 2px solid $white;
		text-transform: uppercase;
		color: $white;
		border-radius: 4px;
		transition: all 0.25s ease-in-out;
		&:hover {
			background: $white;
			color: $link-color;
		}
	}
}
.cssbutton {
	width:200px;
	margin:15px auto;
	text-align:center;
	background-color: #333;
	border: 2px solid #ccc;
	border-radius: 10px;
	color: #fff;
	box-shadow:0 5px 0 #ccc;
	line-height: 50px;
	cursor: pointer;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}
.cssbutton2 {
	width:200px;
	margin:15px auto;
	text-align:center;
	background-color: #333;
	border: 2px solid #ccc;
	border-radius: 10px;
	box-shadow:0 5px 0 #ccc;
	color: #fff;
	line-height: 50px;
	cursor: pointer;
}


header {
	background: $white;
	color: $black;
	&.masthead {
		h1 {
			text-transform: uppercase;
			font-size: 55px;
			font-weight: bold;
			color: $white;
		}
		h2, h3 {
			color: $white;
		}
	}
}

#wrap {
	padding-top: 70px;
	&.home {
		padding: 0;
	}
}

.section-heading {
	padding: 30px 0;
	h2 {
		font-size: 35px;
		text-align: center;
	}
	h1, h2, h3 {
		text-transform: uppercase;
		font-weight: bold;
	}
	h1 span, h2 span, h3 span {
		color: $link-color;
	}
	h4 {
		color: $gray-5;
		line-height: 22px;
	}
	i {
		color: #d9d9d9;
	}
	span.divider {
		margin: 20px auto;
		float: none;
	}
	&.white {
		color: $white;
	}
}

span {
	&.its {
		color: $link-color;
		text-transform: uppercase;
	}
	&.divider {
		height: 10px;
		background: $link-color;
		border-left: 15px solid $black;
		display: block;
		margin: 20px auto;
		width: 30px;
		position: relative;
		&:before {
			position: absolute;
			content: ' ';
			top: 0;
			left: -1px;
			width: 1px;
			height: 10px;
			background: $white;
		}
		&.grey {
			border-color: $gray-9;
			&:before {
				background: $gray-2;
			}
		}
	}
}




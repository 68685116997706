.text-white {
	color:$white;
}
.text-pink {
	color: #ff7ca7;
}
.text-brown {
	color:#b87f4a;
}
.bold {
	font-size: 1.1em;
	font-weight: bold;
}
.require{
	background-color:#ff0000;
	color:$white;
	font-size:0.8em;
	padding:1px 5px;
	margin-left:5px;
}
.any{
	background-color:#8d8d8d;
	color:$white;
	font-size:0.8em;
	padding:1px 5px;
	margin-left:5px;
}

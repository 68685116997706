.carousel-indicators {
	&.vertical {
		right: 15px;
		left: inherit;
		margin-left: 0;
		width: 20px;
		bottom: 0px;
		li {
			display: block;
			margin: 10px 0;
			border-radius: 2px;
			width: 15px;
			height: 15px;
			border: 2px solid white;
			transition: all 0.25s ease-in-out;
			&.active {
				margin: 10px 0;
				width: 15px;
				height: 15px;
				border: 2px solid white;
			}
		}
	}
	li {
		display: inline-block;
		margin: 10px 0;
		border-radius: 2px;
		width: 15px;
		height: 15px;
		border: 2px solid white;
		transition: all 0.25s ease-in-out;
		&.active {
			margin: 10px 0;
			width: 15px;
			height: 15px;
			border: 2px solid white;
		}
	}
}

footer#site-footer {
	padding: 40px 0;
	background: $gray-2;
	color: $white;
	text-align: center;
	span.divider {
		float: none;
	}
	h4, h5 {
		text-transform: uppercase;
	}
	a img.top {
		margin: 20px 0;
	}
	ul.list-inline > li > a {
		color: $white;
		&:hover {
			text-decoration: underline;
		}
	}
}



.go-down {
	position: absolute;
	bottom: 20px;
	right: 49.2%;
	z-index: 999;
	a {
		color: $white;
	}
}

.alignleft {
	display: inline;
	float: left;
	margin-right: 1.5em;
	margin-bottom: 1em;
}

.alignright {
	display: inline;
	float: right;
	margin-left: 1.5em;
	margin-bottom: 1em;
}

.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1em;
}

/*Height adjustment*/
.height-top {
	display: table-cell;
	vertical-align: top;
}
.height-center {
	display: table-cell;
	vertical-align: middle;
}
.height-bottom {
	display: table-cell;
	vertical-align: bottom;

}

.img-center {
	margin: 0 auto;
}
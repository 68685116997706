
a {
	color: $link-color;
	transition: all 0.25s ease-in-out;
	text-decoration: none;
	&:hover {
		color: $link-hover-color;
		text-decoration: none;
	}
	&:focus {
		outline: thin dotted;
	}
	&:hover,
	&:active {
		outline: 0;
	}
}



.has-feedback.left {
	.form-control-feedback {
		top: 7px;
		right: inherit;
		left: 0;
	}
	.form-control {
		padding-left: 33px;
		padding-right: 0;
	}
}

#form_ajax {
	.form-control {
		height: 50px;
		padding: 12px;
		border: 1px solid $gray-e;
	}
	#comment {
		height: 200px;
	}
}
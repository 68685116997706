
section {
	&#page, &#blog {
		font-size: 16px;
	}
	&#whoWeAre {
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
		padding: 40px 0;
		.media .media-body h4 {
			font-weight: bold;
		}
	}
	&#about {
		padding: 40px 0;
		img {
			margin: 15px auto;
		}
		.media {
			margin-top: 20px;
			.media-body {
				.media-heading {
					font-weight: bold;
					margin-top: 20px;
					font-size: 16px;
				}
				p {
					margin-top: 20px;
				}
			}
		}
	}
	&#highlights {
		padding: 60px 0;
		background: $gray-e;
		border-top: 1px solid #e0e0e0;
		h4, h5 {
			font-weight: bold;
		}
		h4 {
			text-transform: uppercase;
		}
		p {
			padding: 15px 0;
			color: $gray-9;
			font-size: 13px;
		}
		ul {
			color: $gray-9;
		}
	}
	&#features {
		background-image: url(../img/main/bk-bl.gif);
		padding: 50px 0 0 0;
		background-attachment: fixed;
		text-align: left;
		.media-circle {
			margin: 15px 0;
			.media-body {
				margin-top: 10px;
				h4 {
					font-weight: 600;
					color: white;
				}
				p {
					color: white;
				}
			}
		}
		h2 {
			color: $white;
			text-align: left;
			text-transform: uppercase;
			font-weight: bold;
			font-size: 35px;
			span {
				color: $link-color;
			}
		}
	}
	&#services {
		padding: 40px 0;
		.media-services {
			position: relative;
			padding: 15px;
			background: #f2f2f2;
			border: 2px solid white;
			transition: all 0.25s ease-in-out;
			.media-object {
				background: white;
				width: 70px;
				height: 70px;
				padding: 15px;
				margin: 10px auto;
				color: $gray-5;
			}
			.media-body {
				margin-top: 9px;
			}
			&:hover {
				background: white;
				border-color: #e6e6e6;
				.media-object {
					background: $link-color;
					color: $white;
				}
			}
			&:before {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				border-width: 0 25px 25px 0;
				border-style: solid;
				border-color: $white $white $gray-c $gray-c;
				background: $gray-c;
				display: block;
				width: 0;
				transition: all 0.25s ease-in-out;
				box-shadow: 1px 1px 8px 0px #b3b3b3;
			}
			&:hover:before {
				border-width: 0;
			}
			&.right {
				&:before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					border-width: 25px 25px 0 0;
					border-style: solid;
					border-color: $white $gray-c $white $gray-c;
					background: $gray-c;
					display: block;
					width: 0;
					transition: all 0.25s ease-in-out;
				}
				.media-body {
					h4, p {
						text-align: right !important;
					}
				}
				&:hover:before {
					border-width: 0;
				}
			}
		}
	}
	&#workflow .media {
		.media-object {
			background: $white;
			width: 70px;
			height: 70px;
			padding: 15px;
			margin: 10px auto;
			color: $gray-5;
		}
		.media-body {
			margin-top: 9px;
		}
		&:hover {
			background: white;
			border-color: #e6e6e6;
			.media-object {
				background: $link-color;
				color: $white;
			}
		}
	}
	&#testimonials {
		padding: 40px 0;
		#testimonials-carousel {
			margin: 30px 0;
			.item {
				padding: 0 50px;
				p {
					padding: 0 50px;
					text-align: center;
					font-size: 16px;
				}
				.author {
					position: relative;
					color: $gray-9;
					h5 {
						text-transform: uppercase;
						color: #8c8c8c;
						margin-bottom: 1px;
					}
					p {
						text-align: left;
						padding: 0;
						font-size: 14px;
					}
					&:before {
						content: "____";
						position: absolute;
						top: -10px;
						left: -40px;
						color: $gray-c;
					}
				}
			}
			.carousel-control {
				background: none;
				width: 30px;
				color: #bfbfbf;
			}
		}
	}
	&#buyNow {
		background-image: url(../img/main/bk-bl.gif);
		background-attachment: fixed;
		padding: 65px 0;
		h2 {
			color: $white;
			text-align: left;
			text-transform: uppercase;
			font-weight: bold;
			font-size: 35px;
			span {
				color: $link-color;
			}
		}
		p {
			color: $white;
			padding: 20px 0;
			line-height: 25px;
			font-size: 16px;
		}
	}
	&#price {
		padding: 40px 0;
		.data {
			.price-image {
				position: relative;
				.price-details {
					position: absolute;
					top: 0;
					opacity: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: transparent;
					background-color: rgba(0, 0, 0, 0.55);
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#8C000000,endColorstr=#8C000000);
					zoom: 1;
					transition: all 0.25s ease-in-out;
					text-align: center;
					color: $white;
					h4 {
						margin-top: 50px;
						text-transform: uppercase;
					}
					p {
						position: absolute;
						bottom: 0;
						margin-bottom: 15px;
						text-align: left;
						padding: 0 15px;
						font-size: 13px;
					}
					ul {
						list-style: none;
						display: inline-block;
						position: relative;
						padding: 0;
						li {
							float: left;
							a {
								padding: 5px;
								margin: 2px;
								i {
									transition: all 0.25s ease-in-out;
									color: $white;
								}
								&:hover i {
									color: #428bca;
								}
							}
						}
					}
				}
			}
			.price-name {
				background: #f7f7f7;
				padding: 20px 0;
				transition: all 0.25s ease-in-out;
				h4 {
					margin: 0;
					padding: 0 0 5px 15px;
					text-transform: uppercase;
				}
				p {
					padding-left: 15px;
					color: $gray-9;
					margin: 0;
				}
				&.price-odd {
					background: #fbfbfb;
				}
			}
			&:hover {
				.price-image .price-details {
					opacity: 1;
				}
				.price-name {
					background: $link-color;
					h4, p {
						color: $white;
					}
				}
			}
		}
	}
	&#portfolio {
		padding: 40px 0;
		#filters {
			text-align: center;
			margin: 20px 0;
			button {
				background: none;
				border: 2px solid transparent;
				padding: 8px 10px;
				text-transform: uppercase;
				border-radius: 8px;
				transition: all 0.25s ease-in-out;
				&.current {
					border: 2px solid #428bca;
				}
			}
		}
		#container {
			transition: all 0.25s ease-in-out;
			.item {
				margin: 15px auto;
				.details {
					position: relative;
					.info-wrapper {
						position: absolute;
						opacity: 0;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						transition: all 0.25s ease-in-out;
						background-color: transparent;
						background-color: rgba(0, 0, 0, 0.6);
						filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000,endColorstr=#99000000);
						zoom: 1;
						.info .name-tag {
							position: absolute;
							right: 0;
							left: 0;
							bottom: 0;
							margin: 0 20px 10px 20px;
							h5, p {
								color: white;
								text-transform: uppercase;
							}
							h5 {
								font-size: 16px;
								font-weight: bold;
								a{
									color:white;
									&:hover{
										color:white;
										text-decoration: underline;
									}
								}
							}
							span.divider {
								margin: 10px 0;
							}
						}
						.heart {
							position: absolute;
							top: 0;
							right: 0;
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 0 50px 50px 0;
							border-color: transparent $link-color transparent transparent;
							i {
								margin-left: 28px;
								margin-top: 8px;
								display: block;
								color: white;
							}
						}
					}
				}
				&:hover .details .info-wrapper {
					opacity: 1;
				}
			}
		}
	}
	&#skills {
		padding: 40px 0;
		background: $gray-c;
		border-top: 1px solid #e0e0e0;
		.skill-circle {
			margin: 15px auto;
		}
		.skill-detail {
			text-align: center;
			text-transform: uppercase;
			font-weight: bold;
			display: block;
		}
	}
}

section {
	&#twitter {
		background-image: url(../img/main/bk-social.jpg);
		background-attachment: fixed;
		padding: 40px 0;
		color: $white;
		text-align: center;
		p {
			font-size: 16px;
			a {
				color: $link-color;
				transition: all 0.25s ease-in-out;
				text-decoration: none;
				&:hover {
					color: $link-hover-color;
				}
			}
		}
		a.btn.btn-empty {
			padding: 10px 20px;
			font-weight: bold;
			font-size: 16px;
			margin: 45px auto;
			position: relative;
			top: 15px;
		}
		.carousel-indicators {
			bottom: -40px;
		}
	}
	&#get-in-touch {
		padding: 40px 0;
		.contact-details .detail {
			margin: 25px auto;
			h4 {
				font-weight: bold;
				text-transform: uppercase;
			}
			p {
				color: $gray-9;
			}
			ul {
				list-style: none;
				padding: 15px 0;
				li {
					margin: 0 5px;
					float: left;
					a {
						padding: 5px;
						color: $gray-9;
						transition: all 0.25s ease-in-out;
						i {
							font-size: 1.7em;
						}
						&:hover {
							color: $link-color;
						}
					}
				}
			}
		}
	}
}


section {
	&#page {
		padding-bottom: 30px;
		.article {
			.date span {
				display: block;
				font-size: 14px;
			}
			.share-post {
				margin: 30px 0;
				span {
					margin-right: 20px;
					border-right: 1px solid #CCC;
					padding-right: 20px;
				}
				a {
					margin: 0 5px;
					color: $gray-9;
					&:hover {
						color: $link-color;
					}
				}
			}
			.comments .media-comments {
				position: relative;
				padding: 15px;
				background: #f2f2f2;
				border: 2px solid $white;
				transition: all 0.25s ease-in-out;
				.media-object {
					background: $white;
					width: 70px;
					height: 70px;
					padding: 15px;
					margin: 10px auto;
					color: $gray-5;
				}
				.media-body {
					margin-top: 9px;
					.media-heading span {
						float: right;
						font-size: 13px;
					}
				}
				p.date {
					background: none;
					color: $gray-c;
					font-size: 12px;
					text-align: left;
					padding: 0;
					text-transform: none;
				}
				&:hover {
					background: $white;
					border-color: #e6e6e6;
					.media-object {
						background: $link-color;
						color: $white;
					}
				}
				&:before {
					content: "";
					position: absolute;
					top: 0;
					right: 0;
					border-width: 0 25px 25px 0;
					border-style: solid;
					border-color: $white $white $gray-c $gray-c;
					background: $gray-c;
					display: block;
					width: 0;
					transition: all 0.25s ease-in-out;
				}
				&:hover:before {
					border-width: 0;
				}
				&.reply {
					margin-left: 80px;
					position: relative;
					overflow: visible;
					&:after {
						position: absolute;
						content: " ";
						width: 2px;
						height: 100%;
						top: 0;
						background: #e6e6e6;
						left: -40px;
					}
				}
			}
		}
		.pagination-wrapper {
			text-align: center;
			.pagination > {
				li > {
					a, span {
						color: #428bca;
					}
				}
				.active > {
					a, span, a:hover, span:hover, a:focus, span:focus {
						background-color: $link-color;
						border-color: $link-color;
						color: white;
					}
				}
			}
		}
	}
	&#blog {
		.article {
			margin: 25px 0;
			span.divider {
				float: none;
				margin: 40px 0;
			}
			h3 a {
				color: #333;
				&:hover {
					color: $link-color;
				}
			}
			.article-details {
				margin: 15px 0;
				.author, .tags {
					display: inline-block;
				}
				.author a, .tags a {
					color: #333;
				}

				.tags {
					i {
						color: $link-color;
					}
					margin-left: 5px;
				}
			}
			.article-data {
				color: $gray-3;
				margin: 20px 0;
			}
			.type {
				background: $link-color;
				text-align: center;
				padding: 10px ;
				margin-bottom: 5px;
				color: white;
				border-radius: 5px;
			}
			.date {
				text-transform: uppercase;
				color:$black;
				text-align: center;
				font-size: 16px;
				line-height: 19px;
				padding: 10px;
				border-radius: 5px;
				span {
					display: block;
					font-size: 14px;
				}
			}
			.share-post {
				margin: 30px 0;
				span {
					margin-right: 20px;
					border-right: 1px solid $gray-c;
					padding-right: 20px;
				}
				a {
					margin: 0 5px;
					color: $gray-9;
					&:hover {
						color: $link-color;
					}
				}
			}
			.comments .media-comments {
				position: relative;
				padding: 15px;
				background: #f2f2f2;
				border: 2px solid $white;
				transition: all 0.25s ease-in-out;
				.media-object {
					background: white;
					width: 70px;
					height: 70px;
					padding: 15px;
					margin: 10px auto;
					color: $gray-5;
				}
				.media-body {
					margin-top: 9px;
					.media-heading span {
						float: right;
						font-size: 13px;
					}
				}
				p.date {
					background: none;
					color: $gray-c;
					font-size: 12px;
					text-align: left;
					padding: 0;
					text-transform: none;
				}
				&:hover {
					background: $white;
					border-color: #e6e6e6;
					.media-object {
						background: $link-color;
						color: $white;
					}
				}
				&:before {
					content: "";
					position: absolute;
					top: 0;
					right: 0;
					border-width: 0 25px 25px 0;
					border-style: solid;
					border-color: $white $white $gray-c $gray-c;
					background: $gray-c;
					display: block;
					width: 0;
					transition: all 0.25s ease-in-out;
				}
				&:hover:before {
					border-width: 0;
				}
				&.reply {
					margin-left: 80px;
					position: relative;
					overflow: visible;
					&:after {
						position: absolute;
						content: " ";
						width: 2px;
						height: 100%;
						top: 0;
						background: #e6e6e6;
						left: -40px;
					}
				}
			}
		}
		.pagination-wrapper {
			text-align: center;
			.pagination > {
				li > {
					a, span {
						color: $link-color;
					}
				}
				.active > {
					a, span, a:hover, span:hover, a:focus, span:focus {
						background-color: $link-color;
						border-color: $link-color;
						color: white;
					}
				}
			}
		}
	}
}






section {
	.side-block {
		margin-top: 25px;
		&.search {
			input.form-control {
				background: $gray-e;
				border: none;
				height: 40px;
			}
			button {
				border: none;
				background: none;
				i {
					top: 3px;
					right: 8px;
					color: $link-color;
				}
			}
		}
		span.divider {
			margin: 40px 0;
			display: block;
		}
		h4 {
			text-transform: uppercase;
			margin-bottom: 25px;
		}
		p {
			color: $gray-9;
		}
		ul {
			padding-left: 20px;
			margin-bottom: 20px;
			li {
				margin: 5px 0;
				a {
					color: $gray-9;
				}
			}
		}
		.media {
			a.pull-left {
				margin-right: 10px;
			}
			.media-object {
				padding: 0;
				height: 60px;
				width: 60px;
				border-radius: 0;
				border: none;
			}
			.media-body {
				font-size: 13px;
				line-height: 16px;
				color: $gray-9;
				a {
					display: block;
					margin-top: 7px;
				}
			}
		}
		a.tag {
			border: 2px solid $gray-9;
			color: $gray-9;
			padding: 8px;
			border-radius: 5px;
			margin: 5px;
			text-transform: lowercase;
			display: inline-block;
			&:hover {
				border-color: $link-color;
				color: $black;
			}
		}
	}
	&.well {
		border: none;
		border-radius: 0;
		background-image: url(../img/main/bk-social.jpg);
		background-attachment: fixed;
		padding: 45px;
		h2 {
			text-transform: uppercase;
			color: $white;
			font-weight: bold;
		}
		.breadcrumb {
			background: none;
			float: right;
			margin-bottom: 0;
			padding: 28px 15px 5px 15px;
			.active {
				color: white;
			}
		}
	}
	&.page {
		border: none;
		border-radius: 0;
		background-image: url(../img/main/bk-page.jpg);
		background-attachment: fixed;
		padding: 45px;
		h2 {
			text-transform: uppercase;
			color: $white;
			font-weight: bold;
		}
		.breadcrumb {
			background: none;
			float: right;
			margin-bottom: 0;
			padding: 28px 15px 5px 15px;
			.active {
				color: $white;
			}
		}
	}
	&#footerimg {
		border: none;
		background-image: url(../img/main/bk-page.jpg);
		background-attachment: fixed;
		width: 100%;
		height: 200px;
	}
}
section .side-block ul {
	list-style-image: url(../img/bullet-bl.png);
	line-height: 25px;
}

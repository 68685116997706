#gsearch{
	.gsc-search-box {
		position: relative;
	}

	.gsc-search-box .gsc-input-box {
		background-color: #eee;
	}
	.gsc-search-box td.gsc-input,td.gsc-input .gstl_50{
		-webkit-box-sizing: border-box;
		        box-sizing: border-box;
		padding: 0 !important;
		height: 50px;
		vertical-align: top;
	}
	.gsc-search-box .gsib_a {
		padding: 0 !important;
	}
	.gsc-search-box input#gsc-i-id1 {
		-webkit-box-sizing: border-box;
		        box-sizing: border-box;
		height: 50px !important;
		padding: 10px !important;
		border-radius: 0;
		-webkit-box-shadow: 2px 2px 2px 2px rgba(234,234,234,0.5) inset;
		        box-shadow: 2px 2px 2px 2px rgba(234,234,234,0.5) inset;
		background-position: 5px center !important;
	}

	.gsc-search-box .gsib_b {
		position: absolute;
		width: 20px;
		top: 12px;
		left: 70%;
	}
	.gsc-search-box .gsc-input-box, .gsc-search-box .gsc-input-box-hover, .gsc-search-box .gsc-input-box-focus {
		border: none !important;
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
	}
	// .gsc-search-box .gsc-search-button {
	// 	position: relative;
	// 	width:20%;
	// 	border-radius: 0;
	// 	border:1px solid #eee;
	// 	background: #428bca;
	// 	cursor: pointer;
	// }

	// .gsc-search-box .gsc-search-button:before {
	// 	position: absolute;
	// 	top: 25%;
	// 	left: 15%;
	// 	color: #FFF;
	// 	font-size: 16px;
	// }
	.gsc-search-box input.gsc-search-button-v2, .gsc-search-box input.gsc-search-button {
		width: 100%;
		padding: 0 !important;
		opacity: 0;
	}

}



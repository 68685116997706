.sticky {
	display: block;
}

.hentry {
	margin: 0 0 1.5em;
}

.byline,
.updated:not(.published){
	display: none;
}

.single .byline,
.group-blog .byline {
	display: inline;
}

.page-content,
.entry-content,
.entry-summary {
	margin: 1.5em 0 0;
}

.page-links {
	clear: both;
	margin: 0 0 1.5em;
}
.comment-content a {
	word-wrap: break-word;
}
.bypostauthor {
	display: block;
}

.widget {
	margin: 0 0 1.5em;

	/* Make sure select elements fit in widgets. */
	select {
		max-width: 100%;
	}
}
/* Globally hidden elements when Infinite Scroll is supported and in use. */
.infinite-scroll .posts-navigation, /* Older / Newer Posts Navigation (always hidden) */
.infinite-scroll.neverending .site-footer { /* Theme Footer (when set to scrolling) */
	display: none;
}

/* When Infinite Scroll has reached its end we need to re-display elements that were hidden (via .neverending) before. */
.infinity-end.neverending .site-footer {
	display: block;
}

.flexbox-1{
	display:flex;
	.items-1{
		width:150px;
		background-color:#eee;
		text-align:center;
		padding:5px;
		border:1px solid #999;
	}
	.items-logo{
		margin-right: auto;
		background-color:#eee;
		padding:5px;
		border:1px solid #999;
	}
}

.flexbox-2{
	display:flex;
	justify-content: space-between;
	.items-2{
		width:150px;
		background-color:#eee;
		text-align:center;
		padding:5px;
		border:1px solid #999;
	}
}

.flexbox-3{
	display: flex;
	justify-content: center;
	align-items: center;
	.items-3{
		width:200px;
		background-color:#eee;
		padding:5px;
	}
}


#estimate .fixed {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background: rgba(247, 247, 247, 0.9);
	border-top: 1px solid $gray-d;
}

#posts img {
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3), 0 2px 5px rgba(0, 0, 0, 0.5);
	transition: all .2s ease-in-out;
	&:hover {
		box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3), 0 10px 8px rgba(0, 0, 0, 0.5);
	}
}

#search_results > li {
	margin-bottom: 25px;
	padding-bottom: 10px;
	border-bottom: 1px solid $gray-c;
}



.carousel-caption {
	background: rgba(0, 0, 0, 0.5);
	p > a {
		color: #d2d2d2;
	}
}


.article-data {
	font-size: 18px;
	line-height: 2;
	margin: 0 0 30px;
	color: $gray-3;
	p {
		margin-bottom: 30px;
	}
	h3 {
		color: $white;
		background: #3e8acd;
		padding: 15px 15px;
	}
	h4 {
		color: #444444;
		background: #f3f3f3;
		padding: 10px 15px;
		border-left: 3px solid #3e8acd;
	}
	h5 {
		color: #3f3f3f;
		padding: 10px 15px;
		border-bottom: 3px solid #3e8acd;
	}
}
.select::selection {
	background: red;
	color: white;
}


/* styling navbar */

#nav {
	width: 100%;
	margin-bottom: 0;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
	min-height: 70px;
	&.affix-top {
		background-color: rgba(255, 255, 255, 0.5);
		border: none;
	}
	&.affix {
		position: fixed;
		top: 0;
		z-index: 999;
		background: rgba(255, 255, 255, 0.9);
		transition: all 0.25s ease-in-out;
	}
	.navbar-brand {
		padding: 0;
		height: 75px;
		line-height: 75px;
		img {
			margin-top: 10px;
		}
	}
	&.navbar-fixed-top {
		position: fixed;
		top: 0;
		background-color: rgba(255, 255, 255, 0.9);
	}
	.navbar-toggle {
		padding: 10px;
		margin-top: 10px;
		margin-bottom: 9px;
		i {
			font-size: 2em;
		}
	}
	.navbar-nav li {
		a {
			text-transform: uppercase;
			font-weight: bold;
			color: #222;
			transition: all 0.25s ease-in-out;
			&:hover {
				color: $link-color;
				background: none;
			}
		}
		&.social-nav {
			border-left: 1px solid $gray-e;
			padding-left: 5px;
			a {
				float: left;
				padding: 5px 5px;
				i {
					font-size: 1.33333333em;
				}
			}
		}
		&.active a {
			color: $link-color;
		}
	}
}

@media (max-width: 767px) {
	#nav .navbar-nav li a {
		padding-top: 8px;
		padding-bottom: 8px;
	}
	.theme-switcher {
		display: none;
	}
	.navbar-brand {
		margin-left: 20px;
	}
	header.masthead {
		h1 {
			font-size: 30px;
		}
		h2 {
			font-size: 16px;
			margin-top: 20px;
		}
		h3 {
			font-size: 14px;
		}
		.tp-caption a, h1, h2, h3 {
			color: $white;
		}
	}
}

.theme-switcher {
	position: absolute;
	margin-top: 20px;
	.colors {
		display: none;
		overflow: hidden;
		float: left;
	}
	a {
		width: 30px;
		height: 30px;
		float: left;
		display: inline-block;
		&.orange {
			background: $orange-1;
		}
		&.blue {
			background: $link-color;
		}
		&.red {
			background: $red-1;
		}
	}
	.Switcher {
		color: $gray-9;
		text-align: center;
		line-height: 28px;
		border: 1px solid $gray-3;
		&:hover, &.active {
			color: $white;
			background: $gray-5;
			border-color: $gray-5;
		}
	}
}
@media print {
	a[href]:after {
		content: "";
	}
	abbr[title]:after {
		content: "";
	}
}

.pre-loader {
	position: fixed;
	background: #ecf0f1;
	width: 100%;
	height: 100%;
	z-index: 9999999;
	display: block;
	.load-con {
		margin: 20% auto;
		position: relative;
		text-align: center;
	}
}

.spinner {
	margin: 50px auto 0;
	width: 70px;
	text-align: center;
	> div {
		width: 18px;
		height: 18px;
		background-color: #bdc3c7;
		border-radius: 100%;
		display: inline-block;
		animation: bouncedelay 1.4s infinite ease-in-out;
		/* Prevent first frame from flickering when animation starts */
		animation-fill-mode: both;
	}
	.bounce1 {
		animation-delay: -0.32s;
	}
	.bounce2 {
		animation-delay: -0.16s;
	}
}


@keyframes bouncedelay {
	0%,
	80%,
	100% {
		transform: scale(0);
	}

	40% {
		transform: scale(1);
	}
}



.media {
	margin: 50px 0;
	text-align: left;
	overflow: hidden;
	a {
		text-decoration: none;
		&.pull-left {
			margin-right: 25px;
		}
		&.pull-right {
			margin-left: 25px;
		}
		.media-object {
			background: $link-color;
			color: $white;
			border: 4px solid $link-color;
			padding: 18px;
			border-radius: 10px;
			transition: all 0.25s ease-in-out;
			display: block;
			height: 100px;
			width: 100px;
			text-align: center;
			text-decoration: none;
		}
	}
	&:hover .media-object {
		background: $white;
		color: $link-color;
		border-radius: 50%;
	}
	&.right {
		text-align: right;
	}
	.media-body h4 {
		color: $black;
		text-transform: uppercase;
	}
	&.media-circle {
		.media-object {
			border-radius: 50%;
			padding: 10px;
			background: none;
			color: $link-color;
			width: 50px;
			height: 50px;
			margin: 10px auto;
			border: 2px solid white;
		}
		&:hover .media-object {
			background: white;
		}
	}
	&.vertical {
		.media-object {
			background: white;
			width: 70px;
			height: 70px;
			margin: 10px auto;
			color: $gray-5;
		}
		.media-body {
			text-align: center;
		}
		&:hover .media-object {
			background: $link-color;
			color: $white;
		}
	}
}

.flex-video {
	position: relative;
	padding-top: 25px;
	padding-bottom: 67.5%;
	height: 0;
	margin-bottom: 16px;
	overflow: hidden;
	&.widescreen {
		padding-bottom: 57.25%;
	}
	&.vimeo {
		padding-top: 0;
	}
	iframe, object, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

@media only screen and (max-device-width: 800px), only screen and (device-width: 1024px) and (device-height: 600px), only screen and (width: 1280px) and (orientation: landscape), only screen and (device-width: 800px), only screen and (max-width: 767px) {
	.flex-video {
		padding-top: 0;
	}
}

.quote {
	background: $gray-3;
	color: $white;
	border-left: 4px solid $link-color;
	border-radius: 0 5px 5px 0;
	padding: 25px;
	margin-bottom: 15px;
}

:focus {
	outline: 0;
}

canvas {
	width: 200px;
	height: 200px;
	display: block;
	float: none;
}
